import absoluteUrl from "next-absolute-url";
var secureLocalhostAddr = "localhost:3001";
export var getOrigin = function(req) {
    var host = (req === null || req === void 0 ? void 0 : req.headers) ? req.headers.host : window.location.host;
    // built in next absoluteUrl changes the protocol to http if it's localhost
    // we have ssl proxy, so we need to keep it https
    if (host === secureLocalhostAddr) {
        return "https://".concat(host);
    }
    var origin = absoluteUrl(req).origin;
    return origin;
};
