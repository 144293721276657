import { SelfServiceRoutes } from "@finn/cp-utils";
export var getActionItem = function(deal, correctCustomerId) {
    var dealId = deal.id, checkout_hash = deal.checkout_hash, state = deal.state, variant = deal.variant;
    var helperUrl = "".concat(correctCustomerId || (deal === null || deal === void 0 ? void 0 : deal.contact_id), "/").concat(dealId, "/").concat(checkout_hash, "?linksrc=action-item");
    var displayTextId = "mycars.action_texts.".concat(state);
    if (variant === "LEAD" && checkout_hash) {
        return {
            displayTextId: displayTextId,
            url: "checkout/thank_you/".concat(helperUrl)
        };
    }
    switch(state){
        case "CREATED":
            return {
                displayTextId: displayTextId,
                url: "".concat(SelfServiceRoutes.MY_CARS, "/").concat(dealId),
                textClass: "text-orange"
            };
        case "ACTIVE":
            return {
                displayTextId: displayTextId,
                url: "".concat(SelfServiceRoutes.MY_CARS, "/").concat(dealId),
                textClass: "text-green"
            };
        case "INACTIVE":
        case "ENDED":
            return {
                displayTextId: displayTextId,
                url: "".concat(SelfServiceRoutes.MY_CARS, "/").concat(dealId)
            };
        default:
            if (checkout_hash) {
                return {
                    displayTextId: displayTextId,
                    url: "checkout/thank_you/".concat(helperUrl)
                };
            } else {
                return {
                    displayTextId: displayTextId,
                    url: "".concat(SelfServiceRoutes.MY_CARS, "/").concat(dealId)
                };
            }
    }
};
