import { COMMON_DATE_FORMAT, toUniversalTime } from "@finn/ui-utils";
import dayjs from "dayjs";
import { FUEL } from "../types/vehicle";
export var SOURCES = {
    NOCODB: "nocodb",
    AIRTABLE: "airtable",
    BLUE_DRAGON: "blue_dragon"
};
export var isElectricVehicle = function(fuel) {
    return fuel === FUEL.ElectricDE || fuel === FUEL.ElectricEN;
};
export var isVehicleComingSoon = function(vehicle) {
    // availability overrides coming soon status
    return !vehicle.availability && !!(vehicle === null || vehicle === void 0 ? void 0 : vehicle.coming_soon);
};
/**
 * Gets the earliest formatted delivery date.
 *
 * Possible states examples:
 * 17.02.2025 (exact date, no deviation)
 * February 2025 (deviation, same year & month)
 * February/March 2025 (deviation, same year, different month)
 * February 2024/March 2025 (deviation, different year & month)
 */ export var getEarliestDelivery = function(param) {
    var vehicle = param.vehicle, intl = param.intl;
    var availableFrom = vehicle.available_from;
    var deviationInWeeks = vehicle.available_from_deviation_in_weeks;
    var earliestDate = toUniversalTime(new Date(availableFrom));
    if (!deviationInWeeks) {
        return intl.formatDate(earliestDate, COMMON_DATE_FORMAT);
    }
    var availableFromDeviationDate = dayjs(earliestDate).add(deviationInWeeks, "week");
    var earliestMonth = earliestDate.getMonth();
    var deviatedMonth = availableFromDeviationDate.month();
    var earliestYear = earliestDate.getFullYear();
    var deviatedYear = availableFromDeviationDate.year();
    var sameMonth = earliestMonth === deviatedMonth;
    var sameYear = earliestYear === deviatedYear;
    var earliestMonthName = intl.formatMessage({
        id: "general.months.".concat(earliestMonth)
    });
    // Display max. 1 month deviation to avoid unnecessary uncertainty
    var monthNextToEarliest = (earliestMonth + 1) % 12;
    var deviatedMonthName = intl.formatMessage({
        id: "general.months.".concat(monthNextToEarliest)
    });
    if (!sameYear) {
        return "".concat(earliestMonthName, " ").concat(earliestYear, "/").concat(deviatedMonthName, " ").concat(deviatedYear);
    }
    if (!sameMonth) {
        return "".concat(earliestMonthName, "/").concat(deviatedMonthName, " ").concat(deviatedYear);
    }
    return "".concat(earliestMonthName, " ").concat(earliestYear);
};
export var getEquipmentLine = function(vehicle) {
    var equipmentLine = vehicle.source === SOURCES.BLUE_DRAGON ? vehicle.trim_name : vehicle.equipment_line;
    return equipmentLine !== null && equipmentLine !== void 0 ? equipmentLine : "";
};
export var getConfigLineLabel = function(vehicle) {
    var _equipment_line, _engine, _equipment_line1;
    return vehicle.source === SOURCES.BLUE_DRAGON ? (_equipment_line = vehicle.equipment_line) !== null && _equipment_line !== void 0 ? _equipment_line : "" : "".concat((_engine = vehicle.engine) !== null && _engine !== void 0 ? _engine : "", " ").concat((_equipment_line1 = vehicle.equipment_line) !== null && _equipment_line1 !== void 0 ? _equipment_line1 : "");
};
