import { CosmicModuleType } from "@finn/ui-cosmic";
var NO_PADDING_MODULES = [
    CosmicModuleType.WEB_MODULE_CONTENT_EXPERIMENT,
    CosmicModuleType.WEB_MODULE_HERO,
    CosmicModuleType.WEB_MODULE_VIDEOS,
    CosmicModuleType.WEB_MODULE_HERO_2,
    CosmicModuleType.WEB_MODULE_DYNAMIC_PROMO,
    CosmicModuleType.WEB_MODULE_CUSTOM_PLACEHOLDER, 
];
export var hasNoPadding = function(type) {
    if (NO_PADDING_MODULES.includes(type)) return true;
    // prevent extra padding in case of new object in cosmic which isn't supported in code yet
    return !Object.values(CosmicModuleType).includes(type);
};
