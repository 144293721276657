import { Switch } from '@finn/design-system/atoms/switch';
import useHotkeys from '@reecelucas/react-use-hotkeys';
import React from 'react';

type Props = {
  text: string;
  hotkey: string;
  helperText?: string;
  onClick?(value: any): void;
  isActive?: boolean;
};

const DevModalSwitch: React.FC<Props> = ({
  text,
  hotkey,
  helperText,
  onClick,
  isActive,
}) => {
  useHotkeys(hotkey, onClick);

  return (
    <div
      className="pointer md:hover:bg-cotton flex items-center md:w-auto md:py-2"
      onClick={onClick}
    >
      <div
        className="w-full"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <p className="body-14-regular pr-8">{text}</p>
        {(helperText || hotkey) && (
          <p className="body-14-regular text-steel mt-1 pr-8">
            {helperText || hotkey}
          </p>
        )}
      </div>
      <Switch checked={Boolean(isActive)} value="checkedA" color="default" />
    </div>
  );
};
export default DevModalSwitch;
