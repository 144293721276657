import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
import regeneratorRuntime from "/opt/build/repo/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import * as snippet from "@segment/snippet";
import { Locale } from "../types/localization";
import config from "./config";
import { isServer } from "./server";
import { getCurrentSessionData } from "./sessionEvents";
// TODO: remove/merge this when we migrate to the new feature flags
export var BUCKET_DISABLED = "disabled";
var allFeatures = {};
var getPageContext = function() {
    if (isServer()) {
        return {};
    }
    return {
        path: window.location.pathname,
        search: window.location.search,
        title: window.document.title,
        url: window.location.href
    };
};
export var setAllFeatures = function(value) {
    allFeatures = value;
};
export var getActiveFeatures = function() {
    var features = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    var activeFeatures = Object.keys(features).reduce(function(acc, key) {
        if (features[key] !== BUCKET_DISABLED) {
            acc[key] = features[key];
        }
        return acc;
    }, {});
    return activeFeatures;
};
/* eslint-enable @typescript-eslint/naming-convention */ var isTestEmail = function(email) {
    return Boolean(email === null || email === void 0 ? void 0 : email.toLocaleLowerCase().match(/.*\+test.*?@finn\.(auto|com)$/));
};
var shouldTriggerSegmentEvent = function() {
    var hostName = window.location.hostname;
    var userInfo = null;
    try {
        userInfo = JSON.parse(localStorage.getItem("ajs_user_traits") || '""') || null;
    } catch (error) {
        console.error("Error reading from localStorage", error);
    }
    var userEmail = (userInfo === null || userInfo === void 0 ? void 0 : userInfo.email) || null;
    var isTestAccount = isTestEmail(userEmail);
    var isProduction = hostName === "www.finn.com";
    var exceptionForTesting = userEmail === config.TRACKING_TEST_EMAIL;
    var blockTracking = !exceptionForTesting && isTestAccount && isProduction;
    return !isServer() && window.UC_Integrations && !blockTracking;
};
var resolveLocale = function() {
    if (isServer()) {
        return;
    }
    var isUS = window.location.pathname.includes(Locale.ENGLISH_USA);
    if (isUS) return Locale.ENGLISH_USA;
    return Locale.GERMAN_GERMANY;
};
// TODO: this is old code, I do not no which obj type we should use here
// so put any, as it was before
var replaceKey = function(obj, toReplace, replaceWith, defaultVal) {
    if (obj[replaceWith] === undefined) {
        if (obj[toReplace] !== undefined) {
            obj[replaceWith] = !!obj[toReplace];
            delete obj[toReplace];
        } else {
            obj[replaceWith] = defaultVal;
        }
    }
};
var transformDetail = function(detail) {
    var transformedDetail = _object_spread({}, detail);
    replaceKey(transformedDetail, "Segment", "Segment.io", true);
    replaceKey(transformedDetail, "Google Ads (Classic)", "AdWords", false);
    replaceKey(transformedDetail, "Fullstory", "FullStory", false);
    return transformedDetail;
};
export var updateSegmentIntegration = function(detail) {
    var transformedDetail = transformDetail(detail);
    if (!isServer()) {
        window.UC_Integrations = transformedDetail;
    }
};
export var renderSegmentSnippet = function() {
    var opts = {
        apiKey: config.ANALYTICS_WRITE_KEY,
        page: false,
        load: false
    };
    // In case of mobile app, we don't need to render the snippet
    // as we intercept all the segment events
    // eslint-disable-next-line
    // @ts-ignore
    if (window === null || window === void 0 ? void 0 : window.onWebReadyToConnectToApp) {
        return null;
    }
    // Returns the minified version of the snippet on production.
    var isDevEnv = "production" === "development";
    return isDevEnv ? snippet.max(opts) : snippet.min(opts);
};
// TODO in order to avoid cycle dependencies
// we are accessing nativeAppSDK directly instead of getAppSDK method
// in 3-6 months, after native app fully migrated to @finn/ua-app sdk
// this code can be deleted DRI - @andrii.tiertyshnyi
var getNativeAppContext = function() {
    var ref;
    var globalObject = window;
    var nativeAppSDK = globalObject === null || globalObject === void 0 ? void 0 : globalObject.nativeAppSDK;
    return (nativeAppSDK === null || nativeAppSDK === void 0 ? void 0 : (ref = nativeAppSDK.getTrackingProps) === null || ref === void 0 ? void 0 : ref.call(nativeAppSDK)) || {};
};
var trackingActions = {
    identify: function identify(userId, traits) {
        var locale = resolveLocale();
        var activeFeatures = getActiveFeatures(allFeatures);
        var trackFunc = function() {
            var ref;
            window === null || window === void 0 ? void 0 : (ref = window.analytics) === null || ref === void 0 ? void 0 : ref.identify(userId, _object_spread_props(_object_spread({}, traits), {
                features: activeFeatures
            }), {
                integrations: _object_spread_props(_object_spread({}, window.UC_Integrations), {
                    HubSpot: false
                }),
                context: _object_spread({
                    locale: locale
                }, getNativeAppContext())
            });
        };
        if (shouldTriggerSegmentEvent()) {
            trackFunc();
        } else if (!isServer()) {
            window.addEventListener("afterSegmentLoad", trackFunc, {
                once: true
            });
        }
    },
    identifyAnonymous: function identifyAnonymous(traits) {
        var locale = resolveLocale();
        var activeFeatures = getActiveFeatures(allFeatures);
        var trackFunc = function() {
            var ref;
            window === null || window === void 0 ? void 0 : (ref = window.analytics) === null || ref === void 0 ? void 0 : ref.identify(_object_spread_props(_object_spread({}, traits), {
                features: activeFeatures
            }), {
                integrations: _object_spread_props(_object_spread({}, window.UC_Integrations), {
                    HubSpot: false
                }),
                context: _object_spread({
                    locale: locale
                }, getNativeAppContext())
            });
        };
        if (shouldTriggerSegmentEvent()) {
            trackFunc();
        } else if (!isServer()) {
            window.addEventListener("afterSegmentLoad", trackFunc, {
                once: true
            });
        }
    },
    page: function page(path) {
        var locale = resolveLocale();
        var trackFunc = function() {
            var ref, // FullStory event as it is disabled on Segment
            ref1;
            window === null || window === void 0 ? void 0 : (ref = window.analytics) === null || ref === void 0 ? void 0 : ref.page(path, {
                locale: locale,
                path: path
            }, {
                integrations: window.UC_Integrations,
                context: _object_spread_props(_object_spread({
                    locale: locale,
                    path: path
                }, getNativeAppContext()), {
                    features: getActiveFeatures(allFeatures),
                    page: getPageContext()
                })
            });
            window === null || window === void 0 ? void 0 : (ref1 = window.FS) === null || ref1 === void 0 ? void 0 : ref1.event("Page Viewed", {
                path: path,
                locale: locale
            });
        };
        if (shouldTriggerSegmentEvent()) {
            trackFunc();
        } else if (!isServer()) {
            window.addEventListener("afterSegmentLoad", trackFunc, {
                once: true
            });
        }
    },
    track: function track(event, properties) {
        var integrations = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
        var locale = resolveLocale();
        var trackFunc = function() {
            var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee() {
                var ref, // FullStory event as it is disabled on Segment
                ref1, ref2, session_id, session_number;
                return regeneratorRuntime.wrap(function _callee$(_ctx) {
                    while(1)switch(_ctx.prev = _ctx.next){
                        case 0:
                            ;
                            if (!window.gtag) {
                                _ctx.next = 8;
                                break;
                            }
                            _ctx.next = 4;
                            return getCurrentSessionData(window.gtag);
                        case 4:
                            ref2 = _ctx.sent;
                            session_id = ref2.session_id;
                            session_number = ref2.session_number;
                            if (session_id !== undefined && session_number !== undefined) {
                                properties = _object_spread_props(_object_spread({}, properties), {
                                    session_id: session_id,
                                    session_number: session_number
                                });
                            }
                        case 8:
                            window === null || window === void 0 ? void 0 : (ref = window.analytics) === null || ref === void 0 ? void 0 : ref.track(event, properties, {
                                integrations: _object_spread({}, window.UC_Integrations, integrations),
                                context: _object_spread_props(_object_spread({
                                    locale: locale
                                }, getNativeAppContext()), {
                                    page: getPageContext()
                                }),
                                "Facebook Pixel": {
                                    contentType: "vehicle"
                                }
                            });
                            window === null || window === void 0 ? void 0 : (ref1 = window.FS) === null || ref1 === void 0 ? void 0 : ref1.event(event, properties);
                        case 10:
                        case "end":
                            return _ctx.stop();
                    }
                }, _callee);
            }));
            return function trackFunc() {
                return _ref.apply(this, arguments);
            };
        }();
        if (shouldTriggerSegmentEvent()) {
            trackFunc();
        } else if (!isServer()) {
            window.addEventListener("afterSegmentLoad", trackFunc, {
                once: true
            });
        }
    }
};
export function createEventQueue(param) {
    var pauseTillIdentify = param.pauseTillIdentify;
    var queue = [];
    return {
        isPaused: pauseTillIdentify,
        enqueue: function enqueue(type, args) {
            switch(type){
                case "identify":
                    {
                        var _trackingActions;
                        this.isPaused = false;
                        (_trackingActions = trackingActions).identify.apply(_trackingActions, _to_consumable_array(args));
                        break;
                    }
                case "identifyAnonymous":
                    {
                        var _trackingActions1;
                        this.isPaused = false;
                        (_trackingActions1 = trackingActions).identifyAnonymous.apply(_trackingActions1, _to_consumable_array(args));
                        break;
                    }
                case "page":
                    {
                        if (this.isPaused) {
                            queue.push([
                                type,
                                args
                            ]);
                        } else {
                            var _trackingActions2;
                            (_trackingActions2 = trackingActions).page.apply(_trackingActions2, _to_consumable_array(args));
                        }
                        break;
                    }
                case "track":
                    {
                        if (this.isPaused) {
                            queue.push([
                                type,
                                args
                            ]);
                        } else {
                            var _trackingActions3;
                            (_trackingActions3 = trackingActions).track.apply(_trackingActions3, _to_consumable_array(args));
                        }
                        break;
                    }
            }
            if (!this.isPaused) {
                this.dequeue();
            }
        },
        dequeue: function dequeue() {
            if (this.isPaused) {
                return;
            }
            while(queue.length > 0){
                // TODO not sure what actual types should be here,
                // as ts strict complains, to solve this -> set what seems to be right
                var ref = _sliced_to_array(queue.shift(), 2), type = ref[0], args = ref[1];
                trackingActions[type].apply(null, args);
            }
        }
    };
}
// Importing `getAppSdk` from @finn/ua-app creates circular dependency.
// Directly check for `nativeAppSDK` on `window`.
// Note: @ts-expect-error fails linting (Unused '@ts-expect-error' directive)
export var isMobileAppReady = function() {
    return isServer() ? false : // @ts-ignore
    Boolean(window === null || window === void 0 ? void 0 : window.onWebReadyToConnectToApp);
};
export var eventQueue = createEventQueue({
    pauseTillIdentify: !isMobileAppReady()
});
export var trackEvent = function(event, properties) {
    var integrations = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
    eventQueue.enqueue("track", [
        event,
        properties,
        integrations
    ]);
};
export var pageEvent = function(path) {
    eventQueue.enqueue("page", [
        path
    ]);
};
export var identifyEvent = function(userId, traits) {
    eventQueue.enqueue("identify", [
        userId,
        traits
    ]);
};
export var identifyAnonymousUserEvent = function(traits) {
    eventQueue.enqueue("identifyAnonymous", [
        traits
    ]);
};
