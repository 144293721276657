export var Features;
(function(Features) {
    Features["FullStory"] = "fullstory";
    Features["ExpDeductiblesV3"] = "exp-deductibles-v3";
    Features["HideDownPayment"] = "feat-hide-downpayment";
    Features["ExpPDPCarInfo"] = "exp-pdp-carinfo2";
    Features["ExpPCPMultipleSteps"] = "exp-pcp-multiplesteps2";
    Features["ExpPDPAvailabilityLabel"] = "exp-pdp-availabilitylabel-relaunch";
    Features["HideFeatureFilter"] = "feat-hide-features-filter";
    Features["ExpJobAutoLink"] = "exp-jobautolink";
    Features["ExpNewHero"] = "exp-newhero2";
    Features["ExpPLPDateSelector"] = "exp-plpdateselector";
    Features["ExpPCPLogin2"] = "exp-pcp-login-2";
    Features["ExpIncentiveLabels"] = "exp-plp-incentive-labels";
    Features["ExpHPHeroFilters"] = "exp-hp-herofilters";
    Features["ExpInstantDownPayment"] = "exp-instant-downpayment";
})(Features || (Features = {}));
// move the removed experiments here to clean up the cookie
export var RemovedExperiments = [
    "exp-morecarsinslider",
    "exp-fakedoor-downpayment",
    "exp-downpayment-options2",
    "exp-plp-sortandtags",
    "exp-pcp-no-toggle-prices",
    "exp-pcp-downpaymenttooltip",
    "exp-newhero",
    "exp-pdp-carinfo",
    "exp-pcp-multiplesteps",
    "exp-reduced-content",
    "exp-enbw-pilot-2",
    "exp-enbw-pilot",
    "exp-hp-brandmodelherofilters",
    "​exp-plp-grid-v1",
    "exp-popular-filters",
    "exp-pdp-recommendations2",
    "exp-pdp-recommendations",
    "exp-plpdrawer2",
    "exp-location-pdp",
    "exp-hp-heroswap",
    "exp-hp-heroswap2",
    "exp-pcpdropdown2",
    "exp-plpdrawer",
    "feat-productapi-getsinglecar",
    "feat-productapi-getcars",
    "feat-productapi-getfilters",
    "feat-downpayment",
    "exp-pdpctatext-v2",
    "exp-recommended-label-v2",
    "exp-productcard-v3",
    "exp-pcpdropdown",
    "exp-wishlist",
    "exp-minib2b-lp-2",
    "exp-delivery-helper",
    "exp-header-link",
    "exp-combined-payment",
    "exp-plp-grid-v1",
    "exp-pay-later-v4",
    "exp-pdp-availabilitylabel",
    "exp-pdp-availabilitylabel2",
    "exp-pdp-availabilitylabel-v2",
    "exp-pcp-login", 
];
