import dayjs from "dayjs";
export var AVAILABILITY_KEY = "availability";
export var FilterKey;
(function(FilterKey) {
    FilterKey["SORT"] = "sort";
    FilterKey["BRANDS"] = "brands";
    FilterKey["CAR_TYPES"] = "cartypes";
    FilterKey["MODELS"] = "models";
    FilterKey["GEARSHIFTS"] = "gearshifts";
    FilterKey["FUELS"] = "fuels";
    FilterKey["COLORS"] = "colors";
    FilterKey["HAS_HITCH"] = "has_hitch";
    FilterKey["VIEW"] = "view";
    FilterKey["IS_FOR_BUSINESS"] = "is_for_business";
    FilterKey["IS_YOUNG_DRIVER"] = "is_young_driver";
    FilterKey["MIN_PRICE"] = "min_price";
    FilterKey["MAX_PRICE"] = "max_price";
    FilterKey["MIN_PRICE_MSRP"] = "min_price_msrp";
    FilterKey["MAX_PRICE_MSRP"] = "max_price_msrp";
    FilterKey["AVAILABLE_FROM"] = "available_from";
    FilterKey["AVAILABLE_TO"] = "available_to";
    FilterKey["TERMS"] = "terms";
    FilterKey["OFFER_TYPE"] = "offer_type";
    FilterKey["SORT_BY_POPULARITY"] = "sort_by_popularity";
    FilterKey["RETENTION"] = "retention";
    FilterKey["HAS_DEALS"] = "has_deals";
    FilterKey["PRODUCT_GROUP"] = "product_group";
    FilterKey["HAS_RETENTION_DEALS"] = "has_retention_deals";
    FilterKey["FEATURES"] = "features";
    FilterKey["TOTAL_RESULTS"] = "total_results";
})(FilterKey || (FilterKey = {}));
export var SortKey;
(function(SortKey) {
    SortKey["ASC"] = "asc";
    SortKey["DESC"] = "desc";
    SortKey["AVAILABILITY"] = "availability";
    SortKey["DEFAULT"] = "default";
})(SortKey || (SortKey = {}));
export var VehicleViewKey;
(function(VehicleViewKey) {
    VehicleViewKey["AVAILABLE"] = "available_cars";
    VehicleViewKey["AVAILABLE_AND_COMING_SOON"] = "available-and-coming-soon";
    VehicleViewKey["COMING_SOON"] = "coming_soon";
    VehicleViewKey["DISPLAYED_CARS"] = "displayed_cars";
})(VehicleViewKey || (VehicleViewKey = {}));
export var FILTERS_RESPONSE_SWR_FALLBACK_KEY = "filtersResponse";
export var SHORT_DEALS_FILTER = "deals";
export var SHORT_HITCH_FILTER = "hitch";
export var SHORT_YOUNG_DRIVER_FILTER = "young-driver";
export var SHORT_14_DAYS_FILTER = "14-days";
export var SHORT_30_DAYS_FILTER = "30-days";
export var FILTER_DATE_FORMAT = "YYYY-MM-DD";
var today = dayjs();
export var DATE_IN_14_DAYS = today.add(14, "day").format(FILTER_DATE_FORMAT);
export var DATE_IN_30_DAYS = today.add(30, "day").format(FILTER_DATE_FORMAT);
export var PRICING_TYPE = {
    NORMAL: "normal",
    DOWNPAYMENT: "downpayment"
};
