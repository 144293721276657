import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _define_property from "@swc/helpers/src/_define_property.mjs";
import regeneratorRuntime from "/opt/build/repo/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { REQUEST_ID_HEADER_NAME } from "@finn/ui-utils";
import axios from "axios";
import { v4 as uuid } from "uuid";
import { getFirstResponseThatFits } from "./helpers";
export var dataFetcher = function(getEndpoint) {
    return _async_to_generator(regeneratorRuntime.mark(function _callee() {
        var paths, apiPaths, responsesPromises;
        return regeneratorRuntime.wrap(function _callee$(_ctx) {
            while(1)switch(_ctx.prev = _ctx.next){
                case 0:
                    paths = getEndpoint();
                    apiPaths = Array.isArray(paths) ? paths : [
                        paths
                    ];
                    responsesPromises = apiPaths.map(function(path) {
                        var controller = new AbortController();
                        return {
                            request: axios("/".concat(path), {
                                headers: _define_property({}, REQUEST_ID_HEADER_NAME, uuid()),
                                withCredentials: true
                            }).catch(function(error) {
                                return error === null || error === void 0 ? void 0 : error.response;
                            }),
                            cancel: function() {
                                return controller === null || controller === void 0 ? void 0 : controller.abort();
                            }
                        };
                    });
                    return _ctx.abrupt("return", getFirstResponseThatFits(responsesPromises));
                case 4:
                case "end":
                    return _ctx.stop();
            }
        }, _callee);
    }));
};
